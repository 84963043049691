<template>
    <div>
        
    </div>
</template>
<script>
  import axios from 'axios'
  import { gapi } from 'gapi-script';
  import { generalService_dotnet} from '@/website/global'
  import { log } from 'async';
  import { AlertError } from "@/website/global_alert";

  export default {
    components: {
   
    },
    data: () => ({
        pickerApiLoaded:false,
        oauthToken:null,
        userInfo:'',
        code:null,
        clentJson: null,
    }),
    computed: {
    },
    watch: {
    },
    async created() {
        const fragmentParams =  this.getFragmentParams() ;
        
        const accessToken = fragmentParams.access_token;
        if(accessToken != undefined)
        {
            console.log("https://warehouse.packhai.com/connectGoogleDrive");
             this.code = fragmentParams.code
             this.oauthToken = fragmentParams.access_token
            this.pickerApiLoaded = true;
            await this.onPicker();
            this.generateRefresToken();
        }   
    },
    methods: {
    //ทำหรับสร้างรีเฟรชโทเคน
    generateRefresToken(){
      const data = new URLSearchParams();
      data.append('code', this.code);
      data.append('client_secret', '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');
      data.append('redirect_uri', 'https://warehouse.packhai.com/connectGoogleDrive');
      data.append('grant_type', 'authorization_code');
      data.append('client_id', '73555754893-5sjbfn01j3lhjijla4jgtmd361dq4k6h.apps.googleusercontent.com');
      axios.post('https://oauth2.googleapis.com/token?', 
  data.toString(), // Convert URLSearchParams to string
  {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  }
  ).then(response => {
    console.log("generateRefresToken",);
    if(response.data.refresh_token != null && response.data.refresh_token != undefined){
      this.clentJson = JSON.stringify(response.data)
      console.log("done generateRefresToken", this.clentJson );
    }
 
}).catch(error => {
  console.error(error);
});
    },
    //สำหรับรีเฟรชโทเคนใหม่
    refreshAccessToken(){
      const data = new URLSearchParams();
      data.append('refresh_token', this.refresh_token);
      data.append('client_secret', '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');
      data.append('grant_type', 'refresh_token');
      data.append('client_id', '73555754893-5sjbfn01j3lhjijla4jgtmd361dq4k6h.apps.googleusercontent.com');
      axios.post('https://oauth2.googleapis.com/token?', 
  data.toString(), // Convert URLSearchParams to string
  {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  }
).then(response => {
  if(response.data.refresh_token != null && response.data.refresh_token != undefined){
    this.clentJson = JSON.stringify(response.data)
  }
 
}).catch(error => {
  console.error(error);
});
    },
    getFragmentParams() {
    const fragment = window.location.hash.substring(1); // นำ # ออกจาก URL Fragment
    const paramsArray = fragment.split('&'); // แยกแต่ละพารามิเตอร์ด้วย &
    const params = {};
    paramsArray.forEach(param => {
    const [key, value] = param.split('='); // แยกชื่อพารามิเตอร์และค่า
    params[key] = decodeURIComponent(value); // เพิ่มในออบเจ็กต์ params
    });
    return params;
    },
    async getInfoEmail(){
    if(this.pickerApiLoaded == true){
      await fetch('https://openidconnect.googleapis.com/v1/userinfo', {
            headers: {
                Authorization: `Bearer ${this.oauthToken}`,
                Accept: 'application/json',
               'Content-Type': 'application/json'
            }
        })
        .then(response => response.json())
        .then(async data => {
            this.userInfo = data;
            console.log('User profile:', data);
            await this.onPicker();
        })
        .catch(error => {
            // Handle errors during profile data retrieval
            console.error('Error fetching user profile:', error);
        });

       }

    },
    async onPicker(){
    window.gapi.load("picker", () => {
        console.log("Picker Loaded");
        
        this.createPicker();
      });
    },
    async createPicker() {
      var docsView = new  window.google.picker.DocsView()
          .setIncludeFolders(true) 
          .setMimeTypes('application/vnd.google-apps.folder')
          .setSelectFolderEnabled(true);
      if (this.pickerApiLoaded && this.oauthToken) {
        var picker = new window.google.picker.PickerBuilder()
          .enableFeature(window.google.picker.Feature.MULTISELECT_ENABLED)
          .addView(docsView)
          .setOAuthToken(this.oauthToken)
          .setDeveloperKey("AIzaSyCCUDryNUdiqImwUSdga-7lzjnDA79yQe0")
          .setCallback(this.pickerCallback)
          .build();
          
        picker.setVisible(true);
      }
    },
     // callback Picker
    async pickerCallback(data) {
       if (data[window.google.picker.Response.ACTION] === window.google.picker.Action.PICKED) {
        console.log(data.docs);
        axios.post( generalService_dotnet + "GoogleDrive/update-branch-google-drive",
        {
          "branchID": localStorage.getItem("Branch_BranchID"),
          "folderID": data.docs[0].id,
          "folderName": data.docs[0].name,
          "clentJson": this.clentJson ,
          "FolderType": 1
        }
        ).then(async (res) => { 
          console.log(res);
          console.log(this.clentJson);
          this.$router.push("/setting")

          return res.data;
        })
        .catch((error) => {
          this.loading = false;
          this.AlertError();
          return false;
        });
         
    
      }
    },
    
    AlertError,
      
    },
  }
</script>
